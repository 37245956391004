(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/outcome/assets/javascripts/outcome.js') >= 0) return;  svs.modules.push('/components/pool-betting/outcome/assets/javascripts/outcome.js');
"use strict";

const {
  clx
} = svs.components.lbUtils;
const Outcome = _ref => {
  let {
    ariaDescribedBy,
    ariaLabel,
    ariaLabelledBy,
    branding,
    className,
    dataTestId,
    id,
    isDisabled = true,
    isSelected = false,
    label,
    onChange,
    onClick,
    isReadOnly,
    value,
    tabIndex
  } = _ref;
  return React.createElement("div", {
    className: clx('pb_outcome', className, {
      ["pg_outcome--".concat(branding)]: branding
    })
  }, React.createElement("input", {
    checked: isSelected,
    className: "sr-only",
    "data-testid": dataTestId,
    disabled: isDisabled,
    id: id,
    name: label,
    onChange: onChange,
    onClick: onClick,
    readOnly: isReadOnly,
    tabIndex: tabIndex,
    type: "checkbox",
    value: value
  }), React.createElement("label", {
    "aria-describedby": ariaDescribedBy,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    htmlFor: id
  }, label));
};
setGlobal('svs.poolBetting.components.outcome.Outcome', Outcome);

 })(window);